import { FC, useState, useEffect, useRef } from 'react';
import ConfigService from "../../config/config.service";
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Table, Form, Button } from 'react-bootstrap';
import MapView from '../map/map.component';
import Incident from '../../models/incident.model';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import { PencilSquare, Trash } from 'react-bootstrap-icons';
import EditIncident from './edit.incident.component';
import Key from '../../models/key.model';
import Multiselect from 'multiselect-react-dropdown';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import CreateIncident from './create.incident.component';
import{ LatLng } from "leaflet";

const config = ConfigService();

const IncidentsList: FC = () => {
    const [position, setPosition] = useState(new LatLng(0.0, 0.0));

    const { t, i18n } = useTranslation();
    const tableRef = useRef(null);
    const { keycloak } = useKeycloak();
    let headers = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    };
    const [menu, setMenu] = useState('list');

    const [incidents, setIncidents] = useState<Incident[]>([]);
    const [filteredIncidents, setFilteredIncidents] = useState<Incident[]>([]);

    const [keys, setKeys] = useState<Key[]>([]);

    const [editIncidentId, setEditIncidentId] = useState(null);
    const [options, setOptions] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);


    var d = new Date();
    d.setMonth(d.getMonth() - 1);
    const [fromDate, setFromDate] = useState(d);
    const [toDate, setToDate] = useState(new Date());

    const [checkedIncidents, setCheckedIncidents] = useState([]);

    const fromDateFormat = (a: Date) => {
        return `${a.toLocaleString('default', { year: 'numeric' })}-${a.toLocaleString('default', { month: '2-digit' })}-${a.toLocaleString('default', { day: '2-digit' })}T00:00:00.000Z`;
    }

    const toDateFormat = (a: Date) => {
        return `${a.toLocaleString('default', { year: 'numeric' })}-${a.toLocaleString('default', { month: '2-digit' })}-${a.toLocaleString('default', { day: '2-digit' })}T23:59:59.000Z`;
    }
    const getAllIncidents = async (from?: Date, to?: Date) => {

        //?from=${fromDateFormat(from)}&to=${toDateFormat(to)}
        const response = await axios.get(`${config.INCIDENTS_ENDPOINT}`, headers);
        return response.data;
    }

    const getKeys = async () => {
        const response = await axios.get(`${config.KEYS_ENDPOINT}`, headers);
        console.log(response);
        return response.data;
    }

    const processImagesML = async () => {
        const response = await axios.post(`${config.IMAGERECOGNITION_ENDPOINT}`, {}, headers);
        getAllIncidents(fromDate, toDate).then(incidents => {
            let iArray: Incident[] = [];
            incidents.items.forEach((e: Incident) => {
                const incident = new Incident(e.title, e.descriptionEn, e.descriptionRu,
                    e.lat, e.lng, new Date(e.createTimestamp),
                    e.fileName, e.status, e._id, e.type, e.predictions, e.originalFileName,
                    e.drones, e.volume, e.address, e.maps_google, e.location_picture);
                iArray.push(incident);
            });
            setIncidents(iArray);
            setFilteredIncidents(iArray);
        });
    }

    useEffect(() => {
        getAllIncidents(fromDate, toDate).then(incidents => {
            let iArray: Incident[] = [];
            incidents.items.forEach((e: Incident) => {
                const incident = new Incident(e.title, e.descriptionEn, e.descriptionRu,
                    e.lat, e.lng, new Date(e.createTimestamp),
                    e.fileName, e.status, e._id, e.type, e.predictions, e.originalFileName,
                    e.drones, e.volume, e.address, e.maps_google, e.location_picture);
                iArray.push(incident);
            });
            setIncidents(iArray);
            setFilteredIncidents(iArray);
        });

        getKeys().then(keys => {
            let kArray: Key[] = [];
            let oArray: any[] = [];
            keys.forEach((e: Key) => {
                kArray.push(new Key(e.name, e.identifier, e._id, e.color, e.icon));
                oArray.push({ id: e.identifier, name: e.name });
            });
            setKeys(kArray);
            console.log(oArray);
            setOptions(oArray);
        })

    }, [fromDate, toDate, menu]);

    const handleApprove = async (e: any) => {
        let ids = checkedIncidents.map((a) => a._id);
        let body = {
            field: 'status',
            value: 'APPROVED',
            ids: ids
        }
        await axios.patch(config.INCIDENTS_ENDPOINT, body, headers);
        getAllIncidents(fromDate, toDate).then(incidents => {
            let iArray: Incident[] = [];
            incidents.items.forEach((e: Incident) => {
                const incident = new Incident(e.title, e.descriptionEn, e.descriptionRu,
                    e.lat, e.lng, new Date(e.createTimestamp),
                    e.fileName, e.status, e._id, e.type, e.predictions, e.originalFileName,
                    e.drones, e.volume, e.address, e.maps_google, e.location_picture);
                iArray.push(incident);
            });
            setIncidents(iArray);
            setFilteredIncidents(iArray);
        });
    }

    const handleClick = (e: any) => {
        setEditIncidentId(e.currentTarget.id.replace('pencil', ''));
        setMenu('edit');
    }

    const handleDelete = async (e: any) => {
        const id = e.currentTarget.id.replace('pencilDelete', '');
        const i = incidents.find((a: any) => a._id == id);
        if (window.confirm(`${t('incidents.deletePopup')} ${i.title}, ${i.getDescription(i18n.language)}`) == true) {
            await axios.delete(`${config.INCIDENTS_ENDPOINT}/${id}`, headers);
            getAllIncidents(fromDate, toDate).then(incidents => {
                let iArray: Incident[] = [];
                incidents.items.forEach((e: Incident) => {
                    const incident = new Incident(e.title, e.descriptionEn, e.descriptionRu,
                        e.lat, e.lng, new Date(e.createTimestamp),
                        e.fileName, e.status, e._id, e.type, e.predictions, e.originalFileName,
                        e.drones, e.volume, e.address, e.maps_google, e.location_picture);
                    iArray.push(incident);
                });
                setIncidents(iArray);
                setFilteredIncidents(iArray);
            });
            setMenu('list');
        }
    }

    const onSelect = (selectedList: any, selectedItem: any) => {

        let sArray: string[] = [];
        selectedList.forEach((e: any) => {
            sArray.push(e.id);
        });

        let fArray = incidents.filter(a => sArray.includes(a.type));
        setFilteredIncidents(fArray);
    }

    const filterIncidents = (search: string) => {
        let fArray = incidents.filter(a => a.lat.toString().indexOf(search) !== -1
            || a.lng.toString().indexOf(search) !== -1
            || a.title.toLowerCase().indexOf(search) !== -1
            || a.descriptionEn.toLowerCase().indexOf(search) !== -1
            || a.descriptionRu.toLowerCase().indexOf(search) !== -1);
        if (sortBy) {
            fArray.sort((a: any, b: any) => {
                if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
                if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            });
        }
        setFilteredIncidents(fArray);

    }

    const onRemove = (selectedList: any, removedItem: any) => {
        console.log('selectedList: ', selectedList);
        console.log('selectedItem: ', removedItem);

        let sArray: string[] = [];
        selectedList.forEach((e: any) => {
            sArray.push(e.id);
        });

        if (sArray.length === 0) {
            setFilteredIncidents(incidents);
        } else {
            let fArray = incidents.filter(a => sArray.includes(a.type));
            if (sortBy) {
                fArray.sort((a: any, b: any) => {
                    if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
                    if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
                    return 0;
                });
            }
            setFilteredIncidents(fArray);
        }

    }

    const isNotImage = (fileName: string) => {
        if (fileName?.indexOf(".doc") !== -1 || fileName?.indexOf(".xls") !== -1
            || fileName?.indexOf(".docx") !== -1 || fileName?.indexOf(".xlsx") !== -1
            || fileName?.indexOf(".ppt") !== -1 || fileName?.indexOf(".pptx") !== -1
            || fileName?.indexOf(".pdf") !== -1 || fileName?.indexOf(".csv") !== -1
            || fileName?.indexOf(".odp") !== -1 || fileName?.indexOf(".ods") !== -1
            || fileName?.indexOf(".odt") !== -1) {
            return true;
        }
        return false;
    }
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');

    useEffect(() => {
        const filIncidents = filteredIncidents.sort((a: any, b: any) => {
            if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });

        setFilteredIncidents(filIncidents);

    }, [sortBy, sortOrder, filteredIncidents]);

    const handleSort = (column: any) => {
        console.log('handlesort: ' + column);
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }

    };

    const getDescriptionString = (language: any) => {
        if ('ru' === language) {
            return 'descriptionRu';
        } else {
            return 'descriptionEn';
        };
    }

    const renderSortIndicator = (column: any) => {
        if (sortBy === column) {
            return sortOrder === 'asc' ? ' ↑' : ' ↓';
        }
        return null;
    };

    return (
        <Container fluid>
            <Row>
                {
                    menu === 'list' &&
                    <Col md={8} style={{color: '#fff', textDecorationColor: '#fff'}}>
                        <Row style={{ paddingTop: 10 }}>

                            <Col md={2}>{t('incidents.keysSearch')}</Col>
                            <Col md={6}>
                                <Multiselect id="keysMultiSelect" displayValue='name' options={options} selectedValues={selectedValues}
                                    onSelect={onSelect} showCheckbox={true} onRemove={onRemove} />
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                            <Col md={2}>
                                {t('incidents.search')}
                            </Col>
                            <Col md={4}>
                                <Form.Control type="text" onChange={(s) => filterIncidents(s.target.value.toLowerCase())} />
                            </Col>
                            <Col md={4}>


                                {
                                /*&nbsp;
                                <Button onClick={processImagesML}>{t('incidents.ml')}</Button>
                                */}
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                            <Col >
                                <span style={{ paddingRight: 10 }}>
                                    <Button variant="primary" onClick={handleApprove}>
                                        {t('incidents.approve')}
                                    </Button>
                                </span>
                                <span style={{ paddingRight: 10 }}>
                                    <Button variant="primary">
                                        {t('incidents.reject')}
                                    </Button>
                                </span>
                                <span style={{ paddingRight: 10 }}>
                                    <DownloadTableExcel
                                        filename="news"
                                        sheet="news"
                                        currentTableRef={tableRef.current}
                                    >
                                        <Button>{t('incidents.export')}</Button>
                                    </DownloadTableExcel>
                                </span>
                                <span>
                                    <Button variant="primary" onClick={() => setMenu('create')}>
                                        {t('incidents.createObject')}
                                    </Button>
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <br />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form>
                                    <Table style={{color: '#fff', textDecorationColor: '#fff'}} ref={tableRef}>
                                        <thead>
                                            <tr>
                                                <th colSpan={2} onClick={() => handleSort('title')}>{t('incidents.title')} {renderSortIndicator('title')}</th>
                                                <th onClick={() => handleSort(`${getDescriptionString(i18n.language)}`)}>{t('incidents.description')} {renderSortIndicator(`${getDescriptionString(i18n.language)}`)}</th>
                                                <th onClick={() => handleSort('status')}>{t('incidents.status')} {renderSortIndicator('status')}</th>
                                                <th></th>
                                                <th>{t('incidents.action')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredIncidents.map((incident: Incident) => <tr key={`tr-${incident._id}`}>
                                                <td><Form.Check
                                                    type="checkbox"
                                                    id={`default-${incident._id}`}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setCheckedIncidents([
                                                                ...checkedIncidents,
                                                                {
                                                                    _id: incident._id
                                                                },
                                                            ]);
                                                        } else {
                                                            setCheckedIncidents(
                                                                checkedIncidents.filter((inc) => inc._id !== incident._id),
                                                            );
                                                        }
                                                    }}
                                                    disabled={!(incident.status === 'DRAFT')}
                                                /></td>
                                                <td>{incident.title}</td>
                                                <td>{
                                                    (incident != null
                                                        && incident.getDescription(i18n.language) != null
                                                        && incident.getDescription(i18n.language).length >= 50) &&
                                                    <div>
                                                        {incident.getDescription(i18n.language).substring(0, 50)}...
                                                    </div>
                                                }
                                                    {
                                                        (incident != null
                                                            && incident.getDescription(i18n.language) != null
                                                            && incident.getDescription(i18n.language).length < 50) &&
                                                        incident.getDescription(i18n.language)
                                                    }</td>
                                                <td>{incident.status}</td>
                                                <td style={{ fontSize: 10 }}>
                                                    {
                                                        incident.fileName
                                                        && incident.fileName.map((f: any, index: any) => {
                                                            if (isNotImage(f)) {
                                                                return <><a href={`${config.IMAGES_ENDPOINT}/${f}`}>{incident.originalFileName[index]}</a><br /></>;
                                                            } else {
                                                                return <><img src={`${config.IMAGES_ENDPOINT}/${f}`} crossOrigin={""} height={50} width={50} /><br /></>
                                                            }
                                                        })
                                                    }
                                                </td>
                                                <td style={{ width: 5 }}><PencilSquare id={`pencil${incident._id}`}
                                                    key={`pencil${incident._id}`}
                                                    onClick={handleClick} />
                                                    <Trash
                                                        id={`pencilDelete${incident._id}`}
                                                        key={`pencilDelete${incident._id}`}
                                                        onClick={handleDelete} />
                                                </td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                }
                {
                    menu === 'edit' && <Col md={8}><EditIncident incidentId={editIncidentId} setMenu={setMenu} /></Col>
                }
                {
                    menu === 'create' && <Col md={8}><CreateIncident setMenu={setMenu} setPosition={setPosition}/></Col>
                }
                <Col md={4}><MapView incidents={filteredIncidents} keys={keys} position={position} /></Col>
            </Row>
        </Container>
    )
}

export default IncidentsList;