import { useState, FC, useEffect } from 'react';
import axios from 'axios';
import ConfigService from '../../config/config.service';
import Key from '../../models/key.model';
import { useTranslation } from 'react-i18next';
import { Container, Table, Row, Col, Button } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';
import { PencilSquare } from 'react-bootstrap-icons';
import CreateKey from './create.key.component';
import { Icon } from '@mui/material';

const config = ConfigService();

const KeysList: FC = () => {
    const [keys, setKeys] = useState<Key[]>([]);
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const [menu, setMenu] = useState('list');
    const [editKeyId, setEditKeyId] = useState(null);


    const getAllKeys = async () => {
        let headers = {
            headers: {
                'Authorization': 'Bearer ' + keycloak.token
            }
        };
        const response = await axios.get(config.KEYS_ENDPOINT, headers);
        console.log(response);
        return response.data;
    }

    useEffect(() => {


        getAllKeys().then(keys => {
            console.log(keys)
            setKeys(keys);
        });
    }, [menu]);

    const handleEditClick = (e: any) => {
        setEditKeyId(e.currentTarget.id.replace('pencil', ''));
        setMenu('edit');
    }

    return (
        <Container >
            <Row>
                <Col md={6}>
                    <Button onClick={() => setMenu('new')}>{t('keys.createKey')}</Button>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    &nbsp;
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Table bordered  style={{color: '#fff', textDecorationColor: '#fff'}}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Identifier</th>
                                <th>Color</th>
                                <th>Icon</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {keys.map((key: Key) => <tr key={key.identifier}>
                                <td>{key.name}</td>
                                <td>{key.identifier}</td>
                                <td>
                                    <div>
                                        <div style={{
                                            width: '36px',
                                            height: '14px',
                                            borderRadius: '2px',
                                            background: `${key.color}`
                                        }} />
                                    </div>
                                </td>
                                <td><Icon>{key.icon}</Icon></td>
                                <td><PencilSquare id={`pencil${key._id}`}
                                    key={`pencil${key._id}`}
                                    onClick={handleEditClick} /></td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Col>
                {
                    menu === 'new' && <Col md={6}><CreateKey setMenu={setMenu} /></Col>
                }
                {
                    menu === 'edit' && <Col md={6}><CreateKey keyId={editKeyId} setMenu={setMenu} /></Col>
                }
            </Row>
        </Container>

    )
}

export default KeysList;