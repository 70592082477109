export default class Key {
    name: string;
    identifier: string;
    _id: string;
    color: string;
    icon: string;
    constructor(name: string, identifier: string, id: string, color: string, icon: string) {
        this.name = name;
        this.identifier = identifier;
        this._id = id;
        this.color = color;
        this.icon = icon;
    }
}