import { LatLng } from "leaflet";

export default class Incident {
    title: string;
    descriptionEn: string;
    descriptionRu: string;
    lat: number;
    lng: number;
    createTimestamp: Date;
    fileName: string[];
    status: string;
    _id: string;
    type: string;
    predictions: string[];
    originalFileName: string[];
    drones: string;
    volume: string;
    address: string;
    maps_google: string;
    location_picture: string;


    constructor(title: string, descriptionEn: string, descriptionRu: string, lat: number, lng: number,
        createTimestamp: Date, fileName: string[], status: string,
        id: string, type: string,
        predictions: string[], originalFileName: string[],
        drones: string, volume: string, address: string,
        maps_google: string, location_picture: string) {
        this.title = title;
        this.descriptionEn = descriptionEn;
        this.descriptionRu = descriptionRu;
        this.lat = lat;
        this.lng = lng;
        this.createTimestamp = createTimestamp;
        this.fileName = fileName;
        this.status = status;
        this._id = id;
        this.type = type;
        this.predictions = predictions;
        this.originalFileName = originalFileName;
        this.drones = drones;
        this.volume = volume;
        this.address = address;
        this.maps_google = maps_google;
        this.location_picture = location_picture;

    }

    public getPosition(): LatLng {
        return new LatLng(this.lat, this.lng);
    }

    public getDescription(language:string): string {
        if ('ru' === language) {
            return this.descriptionRu;
        } else {
            return this.descriptionEn;
        };
    }

    public getCreateTimestampIsoDate(): string {
        //return this.createTimestamp.toLocaleString('default', { year: 'numeric', month: '2-digit', day: '2-digit' });
        return `${this.createTimestamp.toLocaleString('default', { year: 'numeric' })}-${this.createTimestamp.toLocaleString('default', { month: '2-digit' })}-${this.createTimestamp.toLocaleString('default', { day: '2-digit' })} ${this.createTimestamp.toLocaleString('default', { hour: '2-digit' })}:${this.createTimestamp.toLocaleString('default', { minute: '2-digit' })}`;
    }
}

// toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric' })