import { FC, useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import ConfigService from "../../config/config.service";
import { useKeycloak } from '@react-keycloak/web';
import { Trash } from 'react-bootstrap-icons';
import MarkdownEditor from '@uiw/react-markdown-editor';


interface EditIncidentProps {
    incidentId: string;
    setMenu: (e: any) => void;
}

const config = ConfigService();

const EditIncident: FC<EditIncidentProps> = ({ incidentId, setMenu }) => {
    const [title, setTitle] = useState("");
    const [descriptionEn, setDescriptionEn] = useState("");
    const [descriptionRu, setDescriptionRu] = useState("");
    const [type, setType] = useState("");
    const [drones, setDrones] = useState("");
    const [volume, setVolume] = useState("");
    const [lng, setLongitude] = useState("");
    const [lat, setLatitude] = useState("");
    const [incidentFile, setIncidentFile] = useState([]);
    const [isNewFileAdded, setNewFileAdded] = useState(false);
    const [address, setAddress] = useState("");
    const [maps_google, setMapsGoogle] = useState("");
    const [location_picture, setLocationPicture] = useState("");

    const [incident, setIncident] = useState(null);
    const [keys, setKeys] = useState([]);
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    let headers = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    };

    const getAllKeys = async () => {
        const response = await axios.get(`${config.KEYS_ENDPOINT}`, headers);
        return response.data;
    }
    useEffect(() => {
        getAllKeys().then(keys => setKeys(keys));
    }, []);

    const handleClick = async (e: any) => {

        const incidentObject = {
            title: title,
            descriptionEn: descriptionEn,
            descriptionRu: descriptionRu,
            type: type,
            fileName: incident.fileName || [],
            originalFileName: incident.originalFileName || [],
            volume: volume,
            drones: drones,
            address: address,
            maps_google: maps_google,
            location_picture: location_picture
        };
        //let  responseFile;
        //

        if (isNewFileAdded) {
            let bodyFormData = new FormData();
            const ins = incidentFile.length;
            let fileNames = [];
            for (let x = 0; x < ins; x++) {
                bodyFormData.append("incidentFile", incidentFile[x]);
                fileNames[x] = incidentFile[x].name;
            }


            console.log('IMAGES_UPLOAD_ENDPOINT: ', config.IMAGES_UPLOAD_ENDPOINT);

            let responseFile = await axios.post(config.IMAGES_UPLOAD_ENDPOINT,
                bodyFormData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + keycloak.token
                    }

                });
            incidentObject.fileName = incidentObject.fileName.concat(responseFile.data.filename);
            incidentObject.originalFileName = incidentObject.originalFileName.concat(fileNames);
        }

        console.log('incident', incidentObject);

        await axios.put(`${config.INCIDENTS_ENDPOINT}/${incidentId}`, incidentObject, headers);
        setMenu('list');
    }
    const getIncident = async (incidentId: string) => {
        const response = await axios.get(`${config.INCIDENTS_ENDPOINT}/${incidentId}`, headers);
        return response.data;
    }
    useEffect(() => {

        getIncident(incidentId).then(incident => {
            setIncident(incident);
            setTitle(incident.title);
            setDescriptionEn(incident.descriptionEn);
            setDescriptionRu(incident.descriptionRu);
            setType(incident.type);
            setDrones(incident.drones);
            setVolume(incident.volume);
            setMapsGoogle(incident.maps_google);
            setAddress(incident.address);
            setLocationPicture(incident.location_picture);
        });
    }, [incidentId]);

    const isNotImage = (fileName: string) => {
        if (fileName?.indexOf(".doc") !== -1 || fileName?.indexOf(".xls") !== -1
            || fileName?.indexOf(".docx") !== -1 || fileName?.indexOf(".xlsx") !== -1
            || fileName?.indexOf(".ppt") !== -1 || fileName?.indexOf(".pptx") !== -1
            || fileName?.indexOf(".pdf") !== -1 || fileName?.indexOf(".csv") !== -1
            || fileName?.indexOf(".odp") !== -1 || fileName?.indexOf(".ods") !== -1
            || fileName?.indexOf(".odt") !== -1) {
            return true;
        }

        return false;
    }

    const handleDelete = async (e: any) => {
        const id = e.currentTarget.id.replace('deleteFile', '');
        const fileId = id.substring(0, id.indexOf('incidentId'));
        const incidentId = id.substring(id.indexOf('incidentId') + 10);
        const incident = await getIncident(incidentId);
        const files = incident.fileName;
        let fileIndex = files.indexOf(fileId);

        files.splice(fileIndex, 1);
        incident.originalFileName.splice(fileIndex, 1);
        const incidentObject = {
            title: incident.title,
            description: incident.description,
            type: incident.type,
            fileName: files,
            originalFileName: incident.originalFileName
        };
        console.log('incidentObject', incidentObject);
        await axios.put(`${config.INCIDENTS_ENDPOINT}/${incidentId}`, incidentObject, headers);

        getIncident(incidentId).then(incident => {
            console.log('inc', incident);
            setIncident(incident);
            setTitle(incident.title);
            setDescriptionEn(incident.descriptionEn);
            setDescriptionRu(incident.descriptionRu);
            setType(incident.type);
            setMapsGoogle(incident.maps_google);
            setAddress(incident.address);
            setLocationPicture(incident.location_picture);
        });

    }

    const handleFileChange = (e: any) => {
        if (e.target.files !== null) {
            setIncidentFile(e.target.files);
            setNewFileAdded(true);
        }

    }

    const handleCancel = () => {
        setMenu('list');
    }

    return (
        <Container style={{ color: '#fff' }}>
            <Form id="createIncident"  >
                <Row>
                    <Col>
                        <Form.Label>
                            {t('incidents.edit.title')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control type="text" defaultValue={incident?.title} onChange={(e) => setTitle(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>
                        {t('incidents.edit.descriptionEn')}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <MarkdownEditor
                            value={incident?.descriptionEn}
                            height="200px"
                            onChange={(value, viewUpdate) => {
                                setDescriptionEn(value);
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>
                        {t('incidents.edit.descriptionRu')}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <MarkdownEditor
                            value={incident?.descriptionRu}
                            height="200px"
                            onChange={(value, viewUpdate) => {
                                setDescriptionRu(value);
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>
                        {t('incidents.edit.type')}
                    </Col>
                </Row>
                <Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                        <Form.Select required onChange={(e) => { console.log(e.target.selectedIndex); setType(keys[e.target.selectedIndex - 1].identifier); }}>
                            <option></option>
                            {
                                keys && keys.map((key: any) =>
                                    <option id={key.identifier} key={key.identifier} selected={key.identifier === type ? true : false}>{key.name}</option>
                                )
                            }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            {t('incidents.edit.typeError')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>
                            {t('incidents.edit.latitude')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control type="text" defaultValue={incident?.lat} onChange={(e) => setLatitude(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>
                            {t('incidents.edit.longitude')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginBottom: '10px' }}>
                        <Form.Control type="text" defaultValue={incident?.lng} onChange={(e) => setLongitude(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>
                            {t('incidents.edit.drones')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginBottom: '10px' }}>
                        <Form.Control type="text" defaultValue={incident?.drones} onChange={(e) => setDrones(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>
                            {t('incidents.edit.volume')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginBottom: '10px' }}>
                        <Form.Control type="text" defaultValue={incident?.volume} onChange={(e) => setVolume(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>
                            {t('incidents.edit.address')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginBottom: '10px' }}>
                        <Form.Control type="text" defaultValue={incident?.address} onChange={(e) => setAddress(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>
                            {t('incidents.edit.maps_google')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginBottom: '10px' }}>
                        <Form.Control type="text" defaultValue={incident?.maps_google} onChange={(e) => setMapsGoogle(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>
                            {t('incidents.edit.location_picture')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginBottom: '10px' }}>
                        <Form.Control type="text" defaultValue={incident?.location_picture} onChange={(e) => setLocationPicture(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>
                        {
                            incident?.fileName
                            && incident?.fileName.map((f: any, index: any) => {
                                if (isNotImage(f)) {
                                    return <>
                                        <a href={`${config.IMAGES_ENDPOINT}/${f}`}>{incident.originalFileName[index]}</a>
                                        <Trash
                                            id={`deleteFile${f}incidentId${incident?._id}`}
                                            key={`deleteFile${f}incidentId${incident?._id}`}
                                            onClick={handleDelete} />
                                        <br /><br />
                                    </>;
                                } else {
                                    return <>
                                        <img src={`${config.IMAGES_ENDPOINT}/${f}`} crossOrigin={""} height={50} width={50} />
                                        <Trash
                                            id={`deleteFile${f}incidentId${incident?._id}`}
                                            key={`deleteFile${f}incidentId${incident?._id}`}
                                            onClick={handleDelete} />
                                        <br /><br />
                                    </>
                                }
                            })
                        }
                    </Col>
                </Row>
                <Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                        <Form.Control type="file" onChange={handleFileChange} multiple />
                        <Form.Control.Feedback type="invalid">
                            {t('incidents.create.fileError')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Col><br /></Col>

                </Row>
                <Row>
                    <Col md="1">
                        <Button
                            variant="primary"
                            onClick={handleClick}
                        >
                            {t('incidents.edit.update')}
                        </Button>
                    </Col>
                    <Col md="1"></Col>
                    <Col md="1">
                        <Button
                            variant="primary"
                            onClick={handleCancel}
                        >
                            {t('incidents.edit.cancel')}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col><br /></Col>

                </Row>
            </Form>
        </Container>
    );
}
/*

<input type="hidden" id="lat" value={position.lat} />
                        <input type="hidden" id="lng" value={position.lng} />

                        */

export default EditIncident;