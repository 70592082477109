import { FC } from 'react';


const Home: FC = () => {


    return (
        <div>

        </div>
    )
}

export default Home;