import * as React from "react";
import * as ReactDOM from "react-dom";
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import { I18nextProvider } from "react-i18next";
import i18n from './config/translation.service';
import Menu from "./components/menu/menu.component";
import ConfigService from "./config/config.service";

const config = ConfigService();
console.log('config', config);
const keycloakConfig = {
  realm: config.realm,
  url: config.keycloakUrl,
  clientId: config.clientId,
  'public-client': true,
  'enable-cors': true
};

const keycloak = new Keycloak(keycloakConfig);
keycloak.init({ onLoad: 'login-required' }).then(authenticated => {
  ReactDOM.render(
    <ReactKeycloakProvider authClient={keycloak}>
      <React.StrictMode>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <Menu />
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </React.StrictMode>
    </ReactKeycloakProvider>,
    document.getElementById("root")
  );
});
