import { FC, useState, useEffect, CSSProperties } from 'react';
import { Container, Row, Col, Table, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ConfigService from '../../config/config.service';
import { useKeycloak } from '@react-keycloak/web';
import { useCSVReader } from 'react-papaparse';
import Incident from '../../models/incident.model';

const config = ConfigService();


const UploadView: FC = () => {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const { CSVReader } = useCSVReader();

    let headers = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    };
    const [validated, setValidated] = useState(false);
    const [keys, setKeys] = useState([]);
    const [type, setType] = useState("");
    const [uploadData, setUploadData] = useState([])


    useEffect(() => {
        getAllKeys().then(keys => setKeys(keys));
    }, []);

    const getAllKeys = async () => {
        const response = await axios.get(`${config.KEYS_ENDPOINT}`, headers);
        console.log(response);
        return response.data;
    }

    const handleClick = async (event: any) => {
        console.log('HANDLE CLICK');
        const form = event.currentTarget;
        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            console.log('error');
        } else {
            event.preventDefault();
            await axios.post(config.INCIDENTS_ENDPOINT, uploadData, headers);
            setType("");
            setUploadData([]);
        }
        setValidated(true);
    };

    const styles = {
        csvReader: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 10,
        } as CSSProperties,
        browseFile: {
            width: '20%',
        } as CSSProperties,
        acceptedFile: {
            border: '1px solid #ccc',
            height: 45,
            lineHeight: 2.5,
            paddingLeft: 10,
            width: '80%',
        } as CSSProperties,
        remove: {
            borderRadius: 0,
            padding: '0 20px',
        } as CSSProperties,
        progressBarBackgroundColor: {
            backgroundColor: 'red',
        } as CSSProperties,
    };

    return (
        <Container style={{ color: '#fff', textDecorationColor: '#fff' }}>
            <Row>
                <Col md={12} style={{ color: '#fff', textDecorationColor: '#fff' }}>{t('upload.title')}</Col>
            </Row>
            <Form id="uploadData" encType={'multipart/form-data'} noValidate
                validated={validated} onSubmit={handleClick}
            >
                <Row style={{ marginTop: '5px', marginBottom: '5px', color: '#fff', textDecorationColor: '#fff' }}>
                    <Col md={1}>{t('upload.create.type')}</Col>
                    <Col md={2}>
                        <Form.Group as={Col} md="12" controlId="validationCustom03">
                            <Form.Select onChange={(e) => { console.log(e.target.selectedIndex); setType(keys[e.target.selectedIndex - 1].identifier); }}>
                                <option></option>
                                {
                                    keys && keys.map((key: any) =>
                                        <option id={key.identifier} key={key.identifier}>{key.name}</option>
                                    )
                                }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {t('upload.create.typeError')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row style={{ marginTop: '5px', marginBottom: '5px' }}>
                    <Col>
                        <CSVReader
                            onUploadAccepted={(results: any) => {
                                console.log('---------------------------');
                                console.log(results);
                                console.log('---------------------------');
                                let iArray: Incident[] = [];
                                results.data.forEach((e: any) => {
                                    //itle: string, description: string, lat: number, lng: number,
                                    //createTimestamp: Date, fileName: string[], status: string, id: string, type: string
                                    if (e.title !== '' && e.type !== '' 
                                        && !isNaN(e.lat) && !isNaN(e.lng)) {
                                        //console.log(e);
                                        let values = e.type.split(',');

                                        // Use a Set to automatically filter out duplicates
                                        let uniqueValues = new Set(values);

                                        // Convert the Set back to an array
                                        let uniqueValuesArray = Array.from(uniqueValues);

                                        console.log("uniqueValuesArray===" + uniqueValuesArray.length);
                                        for (let i = 0; i < uniqueValuesArray.length; i += 1) {
                                            const incident = new Incident(e.title, e.description_en, e.description_en,
                                                e.lat, e.lng, new Date(),
                                                [], 'APPROVED', null, uniqueValuesArray[i].toString().toUpperCase(), [], [],
                                                e.drones, e.volume, e.address, e.maps_google, e.location_picture
                                            );
                                            iArray.push(incident);
                                        }
                                    }

                                });
                                setUploadData(iArray);
                            }}

                            config={{ header: true, skipEmptyLines: true }}
                        >
                            {({
                                getRootProps,
                                acceptedFile,
                                getRemoveFileProps,
                            }: any) => (
                                <>
                                    <div style={styles.csvReader}>
                                        <button type='button' {...getRootProps()} style={styles.browseFile}>
                                            {t('upload.browse')}
                                        </button>
                                        <div style={styles.acceptedFile}>
                                            {acceptedFile && acceptedFile.name}
                                        </div>
                                        <button {...getRemoveFileProps()} style={styles.remove}>
                                            {t('upload.remove')}
                                        </button>
                                    </div>
                                </>
                            )}
                        </CSVReader>
                    </Col>
                </Row>
                <Row style={{ marginTop: '5px', marginBottom: '5px', color: '#fff', textDecorationColor: '#fff' }}>
                    <Col>
                        <Table style={{ color: '#fff', textDecorationColor: '#fff' }} >
                            <thead>
                                <th>{t('upload.create.title')}</th>
                                <th>{t('upload.create.description_en')}</th>
                                <th>{t('upload.create.description_ru')}</th>
                                <th>{t('upload.create.lat')}</th>
                                <th>{t('upload.create.lng')}</th>
                                <th>{t('upload.create.type')}</th>
                                <th>{t('upload.create.drones')}</th>
                                <th>{t('upload.create.volume')}</th>
                                <th>{t('upload.create.address')}</th>
                                <th>{t('upload.create.maps_google')}</th>
                                <th>{t('upload.create.location_picture')}</th>
                            </thead>
                            <tbody>
                                {uploadData.length > 0 && uploadData.map((i: Incident) => <tr>
                                    <td>{i.title}</td>
                                    <td>{i.descriptionEn}</td>
                                    <td>{i.descriptionRu}</td>
                                    <td>{i.lat}</td>
                                    <td>{i.lng}</td>
                                    <td>{i.type}</td>
                                    <td>{i.drones}</td>
                                    <td>{i.volume}</td>
                                    <td>{i.address}</td>
                                    <td>{i.maps_google}</td>
                                    <td>{i.location_picture}</td>
                                </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row style={{ marginTop: '5px', marginBottom: '5px' }}>
                    <Col>
                        <Button variant="primary" type="submit">
                            {t('upload.submit')}
                        </Button>
                    </Col>
                </Row>
            </Form>

        </Container >
    );
}

export default UploadView;
