declare global {
    interface Window {
        USERS_ENDPOINT: string,
        KEYS_ENDPOINT: string,
        INCIDENTS_ENDPOINT: string,
        realm: string,
        keycloakUrl: string,
        clientId: string,
        IMAGES_ENDPOINT: string,
        ML_ENDPOINT: string,
        IMAGERECOGNITION_ENDPOINT: string,
        CENTER_LAT: number,
        CENTER_LNG: number,
        CENTER_ZOOM: number,
        IMAGES_UPLOAD_ENDPOINT: string
    }
}

const config = {
    USERS_ENDPOINT: window.USERS_ENDPOINT,
    KEYS_ENDPOINT: window.KEYS_ENDPOINT,
    INCIDENTS_ENDPOINT: window.INCIDENTS_ENDPOINT,
    realm: window.realm,
    keycloakUrl: window.keycloakUrl,
    clientId: window.clientId,
    IMAGES_ENDPOINT: window.IMAGES_ENDPOINT,
    ML_ENDPOINT: window.ML_ENDPOINT,
    IMAGERECOGNITION_ENDPOINT: window.IMAGERECOGNITION_ENDPOINT,
    CENTER_LAT: window.CENTER_LAT || 55.17,
    CENTER_LNG:  window.CENTER_LNG || 23.57,
    CENTER_ZOOM: window.CENTER_ZOOM || 7,
    IMAGES_UPLOAD_ENDPOINT: window.IMAGES_UPLOAD_ENDPOINT
}


const ConfigService = () => config;

export default ConfigService;