import { useState, FC, useEffect } from 'react';
import axios from 'axios';
import ConfigService from '../../config/config.service';
import KeysList from './keys.component';
import { useKeycloak } from '@react-keycloak/web';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { SwatchesPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { MaterialIconPicker } from 'react-material-icon-picker';
import { Icon } from '@mui/material';
import "./keys.styles.css";

interface CreateKeyProps {
    keyId?: string;
    setMenu: (e: any) => void;
}

const config = ConfigService();

const CreateKey: FC<CreateKeyProps> = ({ keyId, setMenu }) => {
    const { keycloak } = useKeycloak();
    const [name, setName] = useState("");
    const [identifier, setIdentifier] = useState("");
    const [identifierDisabled, setIdentifierDisabled] = useState(false);

    const [keyColor, setKeyColor] = useState("#fff");
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const { t } = useTranslation();
    const [validated, setValidated] = useState(false);
    const [ic, setIcon] = useState('');



    let headers = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    };
    const getKey = async (keyId: string) => {
        const response = await axios.get(`${config.KEYS_ENDPOINT}/${keyId}`, headers);
        console.log(response);
        return response.data;
    }

    useEffect(() => {
        if (keyId) {
            getKey(keyId).then(k => {
                console.log('key:', k);
                setName(k.name);
                setIdentifier(k.identifier);
                setKeyColor(k.color);
                setIdentifierDisabled(true);
                setIcon(k.icon);
            });
        }

    }, [keyId]);
    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };
    const handleChange = (event: any) => {
        setName(event.target.value);
    }

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleIdentifierChange = (event: any) => {
        setIdentifier(("" + event.target.value).toUpperCase());
    }

    const handleSubmit = async (event: any) => {
        console.log('SUBMIT');
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (keyId) {
                const keyObject = {
                    name: name,
                    color: keyColor,
                    icon: ic
                };
                await axios.patch(`${config.KEYS_ENDPOINT}/${keyId}`, keyObject, headers);
            } else {
                await axios.post(config.KEYS_ENDPOINT, {
                    name: name,
                    identifier: identifier,
                    color: keyColor,
                    icon: ic
                }, headers);
            }
            setMenu('list');
        }
        setValidated(true);


    }

    const handleChangeComplete = (color: any) => {
        console.log(color);
        setKeyColor(color.hex);
        setDisplayColorPicker(false);

    };



    const styles = {
        color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `${keyColor}`,
        },
        swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
        popover: {
            position: 'absolute',
            zIndex: '2',
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
    };

    const onIconClick = (icon: string) => {
        console.log('icon:', icon);
        setIcon(icon);
    }

    return (
        <Container style={{ color: '#fff', textDecorationColor: '#fff' }}>
            <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                        <Form.Label>{t('keys.name')}:</Form.Label>
                        <Form.Control required type="text" placeholder={t('keys.name')} value={name} onChange={handleChange} />
                        <Form.Control.Feedback type="invalid">
                            {t('keys.nameError')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom03">
                        <Form.Label> {t('keys.identifier')}:</Form.Label>
                        <Form.Control type="text" placeholder={t('keys.identifier')} disabled={identifierDisabled} required value={identifier} onChange={handleIdentifierChange} />
                        <Form.Control.Feedback type="invalid">
                            {t('keys.identifierError')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Col md={1}>
                        <label>
                            {t('keys.color')}:
                        </label>
                    </Col>
                    <Col md={1}>
                        <div style={styles.swatch} onClick={handleClick}>
                            <div style={styles.color} />
                        </div>
                        {displayColorPicker ? <div >
                            <div onClick={handleClose} />
                            <SwatchesPicker color={keyColor}
                                onChangeComplete={handleChangeComplete} />
                        </div> : null}
                    </Col>
                </Row>
                <Row>
                    <Col>Ikona: <Icon>{ic}</Icon></Col>
                </Row>
                <Row style={{ color: '#000', textDecorationColor: '#000' }}>
                    <Col>
                        <MaterialIconPicker
                            onIconClick={onIconClick}
                            hsva={{
                                h: 0,
                                s: 0,
                                v: 100,
                                a: 0
                            }}
                        />
                        <br />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <Button type="button" onClick={handleSubmit}>Submit</Button>
                    </Col>
                </Row>
            </Form>

            {keyId === null && <Row>
                <Col md={12}>
                    <KeysList />
                </Col>
            </Row>}
        </Container >
    )
}

export default CreateKey;