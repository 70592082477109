import * as React from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import UsersList from './components/users/users.component';
import CreateUser from './components/users/create.user.component';
import Home from './components/home/home.component';
import KeysList from './components/keys/keys.component';
import CreateKey from './components/keys/create.key.component';
import IncidentsList from './components/incidents/incidents.component';
import AnalyticsView from './components/analytics/analytics.component';
import UploadView from './components/upload/upload.component';

const App: React.FC = () => {
  return (

    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/CreateUser" element={<CreateUser />} />
      <Route path="/List" element={<UsersList />} />
      <Route path="/keys" element={<KeysList />} />
      <Route path="/incidents" element={<IncidentsList />}/>
      <Route path="/analytics" element={<AnalyticsView />}/>
      <Route path="/upload" element={<UploadView />}/>
    </Routes>

  );
}
//      <Route path="/createKey" element={<CreateKey />} />


export default App;
