import { FC, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import ConfigService from "../../config/config.service";
import Incident from '../../models/incident.model';
import { TagCloud } from 'react-tagcloud';
import { format } from "date-fns";
import { useTranslation } from 'react-i18next';

import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    LineChart,
    Line,
    CartesianGrid,
    Scatter,
    ScatterChart
} from "recharts";
const _ = require('lodash');



const config = ConfigService();

const AnalyticsView: FC = () => {
    const { i18n } = useTranslation();

    const { keycloak } = useKeycloak();
    let headers = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    };
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const [wordCount, setWordCount] = useState([]);
    const [scatterData, setScatterData] = useState([]);



    const getAllIncidents = async (from?: Date, to?: Date) => {
        const response = await axios.get(`${config.INCIDENTS_ENDPOINT}`, headers);
        return response.data;
    }

    useEffect(() => {
        getAllIncidents().then(incidents => {
            let iArray:Incident[] = [];
            let str = '';
            let aArray:string[] = [];
            incidents.items.forEach((e: Incident) => {
                iArray.push(new Incident(e.title, e.descriptionEn, e.descriptionRu,
                    e.lat, e.lng, new Date(e.createTimestamp),
                e.fileName, e.status, e._id, e.type, e.predictions, e.originalFileName,
                e.drones, e.volume, e.address, e.maps_google, e.location_picture));
                str = str.concat(' ').concat(e.getDescription(i18n.language).toLowerCase()).concat(' ').concat(e.title.toLowerCase());
                aArray.push(`${(new Date(e.createTimestamp)).getFullYear()}-${(new Date(e.createTimestamp)).getMonth() + 1}-${(new Date(e.createTimestamp)).getDate()}`);
            });
            console.log('aArray:', aArray);
            setIncidents(iArray);
            let groupedScatter = _.groupBy(aArray);
            console.log('groupedScatter: ', groupedScatter);
            let scatteredData = _.values(groupedScatter).map((a: any) => { return { 'd': new Date(a[0]).getTime(), 'val': a.length } });
            console.log('scatterData: ', scatteredData);
            setScatterData(scatteredData);

            let groupedData = _.groupBy(_.words(str));
            let wArray = _.values(groupedData).map((a: any) => { return { "value": a[0], "count": a.length } });
            console.log(wArray);
            setWordCount(wArray);
        });
    }, []);

    const dateFormatter = (date: any) => {
        console.log('date', date);
        if (isFinite(date)) {
            return format(new Date(date), "dd/MMM");
        } else {
            return '';
        }
    };

    return (
        <Container fluid>
            <Row>


            </Row>
            <Row>
                <Col md={4}>
                    <TagCloud
                        minSize={10}
                        maxSize={35}
                        tags={wordCount}
                    />
                </Col>
                <Col md={4}>
                    <AreaChart
                        width={900}
                        height={250}
                        data={scatterData}
                        margin={{
                            top: 10,
                            right: 0,
                            bottom: 10,
                            left: 0
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />

                        <XAxis
                            dataKey='d'
                            domain={['auto', 'auto']}
                            name='Naujienos'
                            scale='time'
                            type='number'
                            tickFormatter={dateFormatter}

                        />
                        <YAxis />
                        <Area
                            type="monotone"
                            dataKey="val"
                            stroke="#ff7300"
                            fill="#ff7300"
                            fillOpacity={0.9}
                        />
                    </AreaChart>

                </Col>
            </Row>
            <Row>
                <Col md={4}>

                    <LineChart width={400} height={400} data={scatterData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey='d'
                            domain={['auto', 'auto']}
                            name='Naujienos'
                            scale='time'
                            type='number'
                            tickFormatter={dateFormatter}

                        />
                        <YAxis />
                        <Line type="monotone" dataKey="val" stroke="#8884d8" />
                    </LineChart>
                </Col>
                <Col md={4}>
                    <ScatterChart
                        width={400}
                        height={400}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <CartesianGrid />
                        <XAxis dataKey='d'
                            domain={['auto', 'auto']}
                            name='Naujienos'
                            scale='time'
                            type='number'
                            tickFormatter={dateFormatter}/>
                        <YAxis type="number" dataKey="val"  />
                        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                        <Scatter name="A school" data={scatterData} fill="#8884d8" />
                    </ScatterChart>
                </Col>
            </Row>
        </Container>
    );
}

export default AnalyticsView;

/*

<VictoryChart
                        theme={VictoryTheme.material}
                        domain={{ x: [0, 5], y: [0, 7] }}
                    >
                        <VictoryAxis
                            scale={{ x: "time" }}
                            tickFormat={t => `${new Date(t).getMonth() + 1}-${(new Date(t)).getDate()}`}
                        />
                        <VictoryScatter
                            style={{ data: { fill: "#c43a31" } }}
                            size={7}
                            data={scatterData}
                        />
                        <VictoryLine
                            interpolation="linear"
                            data={scatterData}
                            style={{ data: { stroke: '#c43a31', strokeWidth: 1 } }}
                        />
                    </VictoryChart>

                    */