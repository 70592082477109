import { FC, useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ConfigService from '../../config/config.service';
import axios from 'axios';
import Incident from '../../models/incident.model';
import { useKeycloak } from '@react-keycloak/web';
import MarkdownEditor from '@uiw/react-markdown-editor';
import{ LatLng } from "leaflet";


const config = ConfigService();


interface CreateIncidentProps {
    setMenu: (e: any) => void;
    setPosition: (e: any) => void;
}

const CreateIncident: FC<CreateIncidentProps> = ({ setMenu, setPosition }) => {
    const { t } = useTranslation();

    const [validated, setValidated] = useState(false);
    const [title, setTitle] = useState("");
    const [descriptionEn, setDescriptionEn] = useState("");
    const [descriptionRu, setDescriptionRu] = useState("");
    const [incidentFile, setIncidentFile] = useState([]);
    const [keys, setKeys] = useState([]);
    const [type, setType] = useState("");
    const [latitude, setLatitude] = useState(0.0);
    const [longitude, setLongitude] = useState(0.0);
    const [drones, setDrones] = useState("");
    const [volume, setVolume] = useState("");
    const [address, setAddress] = useState("");
    const [maps_google, setMapsGoogle] = useState("");
    const [location_picture, setLocationPicture] = useState("");


    const { keycloak } = useKeycloak();
    let headers = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    };

    //const [position, setPosition] = useState({});

    const getAllKeys = async () => {
        let headers = {
            headers: {
                'Authorization': 'Bearer ' + keycloak.token
            }
        };
        const response = await axios.get(config.KEYS_ENDPOINT, headers);
        console.log(response);
        return response.data;
    }

    useEffect(() => {
        getAllKeys().then(keys => setKeys(keys));
    }, []);

    useEffect(() => {
        setPosition(new LatLng(latitude, longitude));
    }, [latitude, longitude]);

    const handleClick = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            let responseFile = null;
            const ins = incidentFile.length;
            let fileNames = [];

            if (ins > 0) {
                let bodyFormData = new FormData();

                for (let x = 0; x < ins; x++) {
                    bodyFormData.append("incidentFile", incidentFile[x]);
                    fileNames[x] = incidentFile[x].name;
                    //console.log(incidentFile[x]);
                }


                console.log('filenames: ', fileNames);


                responseFile = await axios.post(config.IMAGES_UPLOAD_ENDPOINT,
                    bodyFormData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + keycloak.token
                        }
                    });
            }
            console.log('response file: ', responseFile);

            const incident = new Incident(title, descriptionEn, descriptionRu, latitude, longitude,
                new Date(), responseFile?.data?.filename, 'APPROVED', null,
                type, null, fileNames, drones, volume, address, maps_google, location_picture);
            console.log('incident', incident);
            const response = await axios.post(config.INCIDENTS_ENDPOINT, [incident], headers);
            setTitle("");
            setDescriptionRu("");
            setDescriptionEn("");
            //setRefreshIncidents(true);
            //removeMarker();
            setMenu("list");
        }
        setValidated(true);
    };

    const handleFileChange = (e: any) => {
        if (e.target.files !== null) {
            setIncidentFile(e.target.files);

        }

    }

    return (
        <Container style={{ color: '#fff' }}>

            <Form id="createIncident" encType={'multipart/form-data'} noValidate validated={validated}
                onSubmit={handleClick}
            >
                <Row>
                    <Col>
                        <Form.Label>
                            {t('incidents.create.title')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                        <Form.Control required type="text"
                            placeholder={t('incidents.create.enterTitle')} onChange={(e) => setTitle(e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            {t('incidents.create.titleError')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Col>
                        {t('incidents.create.descriptionEn')}
                    </Col>
                </Row>
                <Row style={{ paddingTop: 10 }}>
                    <MarkdownEditor
                        placeholder={t('incidents.create.enterDescriptionEn')}
                        height="200px"
                        onChange={(value, viewUpdate) => {
                            setDescriptionEn(value);
                        }}
                    />
                </Row>
                <Row style={{ paddingTop: 10 }}>
                    <Col>
                        {t('incidents.create.descriptionRu')}
                    </Col>
                </Row>
                <Row style={{ paddingTop: 10 }}>
                    <MarkdownEditor
                        placeholder={t('incidents.create.enterDescriptionRu')}
                        height="200px"
                        onChange={(value, viewUpdate) => {
                            setDescriptionRu(value);
                        }}
                    />
                </Row>
                <Row style={{ paddingTop: 10 }}>
                    <Col>
                        {t('incidents.create.type')}
                    </Col>
                </Row>
                <Row style={{ paddingTop: 10 }}>
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                        <Form.Select required onChange={(e) => { console.log(e.target.selectedIndex); setType(keys[e.target.selectedIndex - 1].identifier); }}>
                            <option></option>
                            {
                                keys && keys.map((key: any) =>
                                    <option id={key.identifier} key={key.identifier}>{key.name}</option>
                                )
                            }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            {t('incidents.create.typeError')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row style={{ paddingTop: 10 }}>
                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                        <Form.Control type="file" onChange={handleFileChange} multiple />
                        <Form.Control.Feedback type="invalid">
                            {t('incidents.create.fileError')}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row style={{ paddingTop: 10 }}>
                    <Col>
                        {t('incidents.create.latitude')}
                    </Col>
                </Row>
                <Row style={{ paddingTop: 10 }}>
                    <Col>
                        <Form.Group as={Col} md="12" controlId="validationCustom05">
                            <Form.Control required type="text"
                                pattern="^-?([0-8]?[0-9]|90)((\.|\,)[0-9]{1,10})$"
                                placeholder={t('incidents.create.enterLatitude')}
                                onChange={(e) => setLatitude(Number(e.target.value))} />
                            <Form.Control.Feedback type="invalid">
                                {t('incidents.create.latitudeError')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row style={{ paddingTop: 10 }}>
                    <Col>
                        {t('incidents.create.longitude')}
                    </Col>
                </Row>
                <Row style={{ paddingTop: 10 }}>
                    <Col>
                        <Form.Group as={Col} md="12" controlId="validationCustom06">
                            <Form.Control required type="text"
                                pattern="^-?([0-8]?[0-9]|90)((\.|\,)[0-9]{1,10})$"
                                placeholder={t('incidents.create.enterLongitude')}
                                onChange={(e) => setLongitude(Number(e.target.value))} />
                            <Form.Control.Feedback type="invalid">
                                {t('incidents.create.longitudeError')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>
                            {t('incidents.edit.drones')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginBottom: '10px' }}>
                        <Form.Control type="text" onChange={(e) => setDrones(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>
                            {t('incidents.edit.volume')}
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginBottom: '10px' }}>
                        <Form.Control type="text" onChange={(e) => setVolume(e.target.value)} />
                    </Col>
                </Row>


                <Row style={{ paddingTop: 10 }}>
                    <Col>
                        <span style={{ paddingRight: 10 }}>

                            <Button
                                variant="primary"
                                onClick={handleClick}
                            >
                                {t('incidents.create.submit')}
                            </Button>
                        </span>
                        <span style={{ paddingRight: 10 }}>

                            <Button
                                variant="primary"
                                onClick={() => setMenu('list')}
                            >
                                {t('incidents.create.cancel')}
                            </Button>
                        </span>
                    </Col>
                </Row>
            </Form>

        </Container >

    );
}

export default CreateIncident;