import { FC, useState, useEffect } from "react";
import L, { LatLng, latLngBounds } from "leaflet";
import { MapContainer, TileLayer, ScaleControl, Marker, Popup } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import Incident from "../../models/incident.model";
import 'leaflet.markercluster';
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import createIcon from './icons.helper';
import Key from '../../models/key.model';
import ConfigService from "../../config/config.service";

const config = ConfigService();

interface IncidentsProps {
    incidents: Incident[],
    keys: Key[],
    position: LatLng
}

const MapView: FC<IncidentsProps> = ({ incidents, keys, position }) => {
    const { t } = useTranslation();

    const [center, setCenter] = useState(new LatLng(config.CENTER_LAT, config.CENTER_LNG));
    const [zoom, setZoom] = useState(config.CENTER_ZOOM);
    const [marker, setMarker] = useState(null)

    const [markersLayer, setMarkersLayer] = useState(L.markerClusterGroup({
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: false,
        maxClusterRadius: 100,
        removeOutsideVisibleBounds: true
    }));

    const [markerBounds, setMarkerBounds] = useState(latLngBounds([]));

    useEffect(() => {
        if (position.lat != 0.0 && position.lng != 0.0) {
            setMarker({ pos: position });
            setCenter(position);
        }
    }, [position]);

    useEffect(() => {
        markersLayer.clearLayers();
        incidents.forEach((e: Incident) => {
            let index = keys.find(k => k.identifier === e.type);

            const lMarker = L.marker([
                e.lat,
                e.lng
            ], { icon: createIcon(index?.color, index?.icon) });

            markerBounds.extend([e.lat, e.lng]);
            let content = e.title;
            let popup = L.popup()
                .setContent(content);
            lMarker.bindPopup(popup);
            markersLayer.addLayer(lMarker);
        });

    }, [incidents, markersLayer, markerBounds, keys]);

    const markerHtmlStyles = `
    background-color: #583470;
    width: 2rem;
    height: 2rem;
    display: block;
    left: -1.5rem;
    top: -1.5rem;
    position: relative;
    border-radius: 3rem 3rem 0;
    transform: rotate(45deg);
    border: 1px solid #FFFFFF`;

    const icon = L.divIcon({
        className: "my-custom-pin",
        iconAnchor: [0, 24],
        //labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: `<span style="${markerHtmlStyles}" />`
    });

    return (
        <MapContainer center={center} zoom={zoom}
            className='markercluster-map'
            maxZoom={18}
            layers={[markersLayer]}
            maxBounds={markerBounds}
        >
            <ScaleControl position="bottomright" />
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
                marker != null && <Marker key={'a-999'}
                    position={marker.pos}
                    draggable={true}
                    //ref={markerRef}
                    //eventHandlers={eventHandlers}
                    icon={icon}

                >
                </Marker>
            }
        </MapContainer>
    );
}
export default MapView;