import { FC } from 'react';
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../language/language.component';
import { Container, Row, Col } from 'react-bootstrap';


const Menu: FC = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <Row>
                <Col md={10}>
                    <Nav defaultActiveKey="/" className="justify-content-center"
                    >
                        <Nav.Item>
                            <Nav.Link href="/" style={{color: '#FEEE03'}} eventKey="/">{t('home.home')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="keys" style={{color: '#FEEE03'}} href="/keys">{t('home.keys')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="incidents" style={{color: '#FEEE03'}} href="/incidents">{t('home.incidents')}</Nav.Link>
                        </Nav.Item>
                        {/*<Nav.Item>
                            <Nav.Link eventKey="analytics" style={{color: '#FEEE03'}} href="/analytics">{t('home.analytics')}</Nav.Link>
    </Nav.Item>*/}
                        <Nav.Item>
                            <Nav.Link eventKey="upload" style={{color: '#FEEE03'}} href="/upload">{t('home.upload')}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col md={2}>
                    <LanguageSwitcher />

                </Col>
            </Row>

        </Container>

    )
}

export default Menu;